import request from "@/config/serve.js";

// 查询国家、地区
export function getAreaList(params) {
  return request("get", "/app/selectCountry", params);
}

// 预约管理、内测管理 审核-数量语言展示
export function auditLanguageInfo(params) {
  return request("get", "/app/selectReviweInfo", params);
}

// 记录用户下载行为
export function recordUrl(params) {
  return request("get", "/file/recordUrl", params);
}
