import Vue from "vue";
import Vuex from "vuex";
import { getAreaList } from "@/common/js/commonApi";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  getters,
  state: {
    menuList: [], // 当前用户的所有菜单
    historyMenuItemList: [], //当前用户访问过的菜单集
    activeMenuPath: "/", // 正在访问的菜单路径
    userInfo: null, //当前用户信息
    btnPermissions: [], // 当前用户在当前路由拥有的操作的权限
    localLanguageIdList: [], // 应用信息中可本地化的地区id列表
    areaList: [], // 地区列表
    releaseArea: [], //发布国家或地区
  },
  mutations: {
    // 修改state中属性key的值为value
    updateState(state, [key, value]) {
      state[key] = value;
    },
    // 添加历史菜单
    pushHistoryMenu(state, menuItem) {
      state.historyMenuItemList.push(menuItem);
    },
    // 删除历史菜单中第index项
    deleteHistoryMenu(state, index) {
      state.historyMenuItemList.splice(index, 1);
    },
    // 清空历史菜单
    clearHistoryMenu(state) {
      state.historyMenuItemList = [];
    },
    // 修改正在访问的菜单路径
    changeActiveMenuPath(state, path) {
      state.activeMenuPath = path;
    },
    // 修改当前登录的用户信息
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    initMenuList(state, data) {
      state.menuList = data;
    },
    changebtnPermissions(state, data) {
      state.btnPermissions = data;
    },
  },
  actions: {
    // 添加历史菜单
    pushHistoryMenu({ commit, state }, menuItem) {
      if (!(menuItem.meta && menuItem.meta.toolTip)) return; // 没有描述的路由不添加
      //判断需要添加的路由是否已经存在
      let isExist =
        state.historyMenuItemList.findIndex(
          (item) => item.path == menuItem.path
        ) !== -1;
      if (isExist) {
        // 对于已经存在的路由不需要添加
        return;
      } else {
        // 添加不存在的添加
        commit("pushHistoryMenu", menuItem);
      }
    },
    /**
     * 更新地区列表方法
     * 当地区列表为空时，更新地区列表。有的时候不会更新
     * list 需要修改和更新全局数据的变量 ----swx
     */
    updateAreaListList({ commit, state }, list) {
      return new Promise((resolve, reject) => {
        if (list) {
          commit("updateState", ["areaList", list]);
          resolve(list);
          return;
        }
        if (state.areaList.length) {
          resolve(state.areaList);
        } else {
          getAreaList({ languageId: 0 }) //只显示中文一种语言
            .then((res) => {
              const list = res.data || [];
              commit("updateState", ["areaList", list]);
              resolve(res.data || []);
            })
            .catch((err) => {
              reject([]);
            });
        }
      });
    },
  },
  modules: {},
});
